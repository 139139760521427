import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Avatar, Stack, CircularProgress, Divider } from '@mui/material';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { CalendarToday, AccessTime, LocationOn, Apartment } from '@mui/icons-material';
import { invitationService } from '../invitationService';
import { useInvitation } from '../../../contexts/InvitationContext';
import { useAuth } from '../../../shared/hooks/useAuth';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

const InvitationLanding = () => {
  const { setInvitationToken } = useInvitation();  
  const [invitation, setInvitation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { inviteToken } = useParams();
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    setInvitationToken(inviteToken);
    const fetchInvitationData = async () => {
      try {
        const data = await invitationService.fetchInvitation(inviteToken);
        setInvitation(data);
      } catch (err) {
        console.error('Error fetching invitation:', err);
        setError(err.message || "An error occurred while fetching the invitation");
      } finally {
        setLoading(false);
      }
    };

    fetchInvitationData();
  }, [inviteToken, setInvitationToken]);

  const handleContinue = async () => {
    try {
      await invitationService.createVisitFromInvitation(inviteToken, user.id);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error creating visit:', error);
      setError('Error al crear la visita: ' + error.message);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!invitation) {
    return <Typography>Invitación no encontrada.</Typography>;
  }

  const residentName = `${invitation.resident.first_name} ${invitation.resident.last_name}`;
  const visitDate = new Date(invitation.date);

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography variant="h4" gutterBottom>Bienvenido</Typography>
      <Typography variant="body1" paragraph>
        Te han invitado a realizar una visita. {user ? 'Continúa para confirmar tu asistencia.' : 'Para confirmar tu asistencia, inicia sesión o regístrate.'}
      </Typography>
      <Box sx={{ bgcolor: 'background.paper', p: 2, borderRadius: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>Invitación de:</Typography>
        <Stack direction="row" spacing={2} alignItems="center" mb={2}>
          <Avatar src={invitation.resident.profile_pic} alt={residentName}>
            {residentName.charAt(0)}
          </Avatar>
          <Box>
            <Typography variant="subtitle1">{residentName}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <CalendarToday fontSize="small" />
              <Typography variant="body2">
                {format(visitDate, "EEEE d 'de' MMMM, yyyy", { locale: es })}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <AccessTime fontSize="small" />
              <Typography variant="body2">
                {format(visitDate, "HH:mm")}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>Lugar de la visita:</Typography>
        <Stack spacing={1}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Apartment fontSize="small" />
            <Typography variant="body2">
              Apartamento: {invitation.apartment.apartment_number}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="flex-start">
            <LocationOn fontSize="small" sx={{ mt: 0.5 }} />
            <Box>
              <Typography variant="body2">
                {invitation.apartment.building.name}
              </Typography>
              <Typography variant="body2">
                {invitation.apartment.building.address}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Box>
      {user ? (
        <Button
          onClick={handleContinue}
          variant="contained"
          fullWidth
        >
          Continuar
        </Button>
      ) : (
        <>
          <Button
            component={Link}
            to={`/login?token=${inviteToken}`}
            variant="contained"
            fullWidth
            sx={{ mb: 2 }}
          >
            Iniciar sesión
          </Button>
          <Button
            component={Link}
            to={`/register?token=${inviteToken}`}
            variant="outlined"
            fullWidth
          >
            Soy nuevo, registrarme
          </Button>
        </>
      )}
      <Typography variant="body2" align="center" sx={{ mt: 2 }}>
        Al iniciar sesión o registrarse, acepta nuestros{' '}
        <Link to="/terms">Términos de servicio</Link> y{' '}
        <Link to="/privacy">Política de Privacidad</Link>
      </Typography>
    </Box>
  );
};

export default InvitationLanding;