import React, { useState, useEffect } from 'react';
import { Typography, Box, Link, Snackbar } from '@mui/material';
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../../../shared/hooks/useAuth';
import { useInvitation } from '../../../contexts/InvitationContext';
import { invitationService } from '../../invitation/invitationService';
import { authService } from '../authService';
import { ROUTES } from '../../../constants/routes';

const Login = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { invitationToken, setInvitationToken } = useInvitation();
  const { login } = useAuth();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setInvitationToken(token);
    }
  }, [location, setInvitationToken]);

  const handleLogin = async ({ email, password }) => {
    try {
      const user = await login(email, password);
      
      if (invitationToken) {
        try {
          await invitationService.createVisitFromInvitation(invitationToken, user.id);
          setSnackbarMessage('Visita creada exitosamente');
          setOpenSnackbar(true);
        } catch (visitError) {
          console.error('Error creating visit:', visitError);
          setSnackbarMessage('Error al crear la visita: ' + visitError.message);
          setOpenSnackbar(true);
        }
      }

      // Check if user profile is complete
      const userProfile = await authService.getUserProfile(user.id);
      
      if (!userProfile || !userProfile.first_name || !userProfile.last_name || !userProfile.document_number) {
        navigate(ROUTES.COMPLETE_REGISTRATION);
      } else {
        navigate(ROUTES.DASHBOARD);
      }
    } catch (error) {
      console.error('Login error:', error);
      setSnackbarMessage(error.message || "Ocurrió un error durante el inicio de sesión");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ mt: 1, maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Iniciar sesión
      </Typography>
      <LoginForm onSubmit={handleLogin} />
      <Typography align="center">
        <Link component={RouterLink} to={ROUTES.FORGOT_PASSWORD} underline="hover">
          Olvidé mi contraseña
        </Link>
      </Typography>
      <Typography align="center" sx={{ mt: 1 }}>
        No tengo cuenta, <Link component={RouterLink} to={`${ROUTES.REGISTER}${invitationToken ? `?token=${invitationToken}` : ''}`} underline="hover">Registrarme</Link>
      </Typography>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Login;