// src/features/invitation/components/VisitList.js

import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { invitationService } from '../invitationService';
import VisitCard from './VisitCard';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import { isToday, isFuture, parseISO, compareAsc, compareDesc } from 'date-fns';
import { toDate } from 'date-fns-tz';

const VisitList = () => {
  const [tabValue, setTabValue] = useState(0);
  const [visits, setVisits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Get the user's time zone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const fetchVisits = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await invitationService.fetchUserVisits();
      setVisits(data);
    } catch (err) {
      console.error('Error fetching visits:', err);
      setError('Failed to fetch visits. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVisits();
  }, [fetchVisits]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleVisitClick = (visitId) => {
    navigate(`/visit/${visitId}`);
  };

  const isActiveVisit = useCallback((visit) => {
    const visitDate = toDate(parseISO(visit.invitation.date), { timeZone: userTimeZone });
    // const now = new Date();
    return isToday(visitDate) || isFuture(visitDate);
  }, [userTimeZone]);

  const getFilteredAndSortedVisits = useCallback(() => {
    return visits
      .filter(visit => tabValue === 0 ? isActiveVisit(visit) : !isActiveVisit(visit))
      .sort((a, b) => {
        const dateA = toDate(parseISO(a.invitation.date), { timeZone: userTimeZone });
        const dateB = toDate(parseISO(b.invitation.date), { timeZone: userTimeZone });
        return tabValue === 0 ? compareAsc(dateA, dateB) : compareDesc(dateA, dateB);
      });
  }, [visits, tabValue, isActiveVisit, userTimeZone]);

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange} 
          aria-label="visit tabs"
          sx={{ mb: 3 }}
          centered
        >
          <Tab label="Activas" />
          <Tab label="Historial" />
        </Tabs>
        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <Typography color="error" align="center">{error}</Typography>
        ) : (
          <Box>
            {getFilteredAndSortedVisits().map(visit => (
              <VisitCard key={visit.id} visit={visit} onVisitClick={handleVisitClick} />
            ))}
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default VisitList;