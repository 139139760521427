// src/features/invitation/components/VisitCard.js

import React from 'react';
import { Card, CardContent, Typography, IconButton, Box } from '@mui/material';
import { CalendarToday, AccessTime, ArrowForward } from '@mui/icons-material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import StatusChip from './StatusChip';

const VisitCard = ({ visit, onVisitClick }) => {
  const visitDate = new Date(visit.invitation.date);

  return (
    <Card sx={{ mb: 2, borderRadius: 4 }}>
      <CardContent sx={{ p: 2 }}>
        <Typography variant="h6" component="div" gutterBottom>
          {visit.invitation.resident.first_name} {visit.invitation.resident.last_name}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <CalendarToday fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {format(visitDate, "d 'de' MMMM, yyyy", { locale: es })}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTime fontSize="small" sx={{ mr: 1 }} />
          <Typography variant="body2" color="text.secondary">
            {format(visitDate, "HH:mm")}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
          <StatusChip status={visit.status} />
          <IconButton 
            onClick={() => onVisitClick(visit.id)} 
            sx={{ 
              bgcolor: 'action.selected', 
              borderRadius: 2,
              '&:hover': {
                bgcolor: 'action.hover',
              },
            }}
          >
            <ArrowForward />
          </IconButton>
        </Box>
      </CardContent>
    </Card>
  );
};

export default VisitCard;