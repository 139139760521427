import { api } from '../../shared/services/api';
import { supabase } from '../../shared/utils/supabaseClient';

export const invitationService = {
  async fetchInvitation(token) {
    return api.get('invitations', {
      select: `
        *,
        resident:users!resident_id (
          first_name,
          last_name,
          profile_pic
        ),
        apartment:apartments!apartment_id (
          apartment_number,
          building:buildings (
            name,
            address
          )
        )
      `,
      filter: { invitation_token: token },
      single: true
    });
  },

  async createVisitFromInvitation(invitationToken, visitorId) {
    return api.rpc('create_visit_from_invitation', {
      p_invitation_token: invitationToken,
      p_visitor_id: visitorId
    });
  },

  async fetchUserVisits() {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No authenticated user');

    return api.get('visits', {
      select: `
        *,
        invitation:invitations (
          date,
          resident:users!resident_id (
            first_name,
            last_name
          ),
          apartment:apartments (
            apartment_number,
            building:buildings (name)
          )
        )
      `,
      filter: { visitor_id: user.id },
      order: { created_at: 'desc' }
    });
  },

  async fetchVisitDetails(visitId) {
    return api.get('visits', {
      select: `
        *,
        invitation:invitations (
          date,
          resident:users!resident_id (
            first_name,
            last_name,
            profile_pic
          ),
          apartment:apartments!apartment_id (
            apartment_number,
            building:buildings (
              name,
              address
            )
          )
        )
      `,
      filter: { id: visitId },
      single: true
    });
  }
};