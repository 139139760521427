import React from 'react';
import { Box, Container } from '@mui/material';
import VisitList from '../../invitation/components/VisitList';

const Dashboard = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Box sx={{ mt: 4 }}>
          <VisitList />
        </Box>
        {/* Add more dashboard widgets here */}
      </Box>
    </Container>
  );
};

export default Dashboard;
