// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import { InvitationProvider } from './contexts/InvitationContext';
import { ROUTES } from './constants/routes';
import MainLayout from './shared/layouts/MainLayout';
import PrivateRoute from './shared/components/PrivateRoute';
import Login from './features/auth/pages/Login';
import Register from './features/auth/pages/Register';
import ForgotPassword from './features/auth/pages/ForgotPassword';
import Dashboard from './features/dashboard/pages/Dashboard';
import InvitationLanding from './features/invitation/pages/InvitationLanding';
import VisitDetail from './features/invitation/pages/VisitDetail';
import NotFound from './shared/components/NotFound';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <InvitationProvider>
        <Router>
          <MainLayout>
            <Routes>
              <Route path={ROUTES.INVITATION} element={<InvitationLanding />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.REGISTER} element={<Register />} />
              <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
              <Route 
                path={ROUTES.DASHBOARD} 
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                } 
              />
              <Route 
                path={ROUTES.VISIT_DETAIL} 
                element={
                  <PrivateRoute>
                    <VisitDetail />
                  </PrivateRoute>
                } 
              />
              <Route path="/" element={<Navigate replace to={ROUTES.LOGIN} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </MainLayout>
        </Router>
      </InvitationProvider>
    </ThemeProvider>
  );
}

export default App;