// src/shared/layouts/MainLayout.js

import React from 'react';
import { Box, Container, AppBar, Toolbar } from '@mui/material';
import { useLocation } from 'react-router-dom';
import ProfileMenu from '../components/ProfileMenu';
import { useAuth } from '../hooks/useAuth';
import { ROUTES } from '../../constants/routes';

const MainLayout = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();
  const isAuthPage = [ROUTES.LOGIN, ROUTES.REGISTER, ROUTES.FORGOT_PASSWORD].includes(location.pathname);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      {user && !isAuthPage ? (
        <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <img 
              src={process.env.PUBLIC_URL + '/images/domus.png'} 
              alt="Domus" 
              style={{ height: '40px' }} 
            />
            <ProfileMenu />
          </Toolbar>
        </AppBar>
      ) : null}
      <Container component="main" sx={{ flexGrow: 1, py: 4, maxWidth: 400, margin: 'auto' }}>
        {(!user || isAuthPage) && (
          <Box sx={{ maxWidth: 300, margin: 'auto', p: 2 }}>
            <img 
              src={process.env.PUBLIC_URL + '/images/domus.png'}
              alt="Domus"
              style={{ width: '100%', marginBottom: '8px' }} // Adjusted marginBottom to 8px
            />
          </Box>
        )}
        {children}
      </Container>
    </Box>
  );
};

export default MainLayout;