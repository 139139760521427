import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Divider, Paper, IconButton, CircularProgress } from '@mui/material';
import { CalendarToday, AccessTime, LocationOn, Apartment, ArrowBack } from '@mui/icons-material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { useParams, useNavigate } from 'react-router-dom';
import { invitationService } from '../invitationService';

const VisitDetail = () => {
  const [visit, setVisit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { visitId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVisitDetails = async () => {
      setLoading(true);
      try {
        const data = await invitationService.fetchVisitDetails(visitId);
        setVisit(data);
      } catch (err) {
        console.error('Error fetching visit details:', err);
        setError('Failed to fetch visit details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchVisitDetails();
  }, [visitId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!visit) {
    return <Typography>Visit not found.</Typography>;
  }

  const invitationDate = new Date(visit.invitation.date);

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', p: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" component="h1" gutterBottom>
          Detalles de invitación
        </Typography>
      </Box>
      <Paper elevation={0} sx={{ p: 3, borderRadius: 4 }}>
        <Typography variant="h6" gutterBottom>Organizador</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <Avatar src={visit.invitation.resident.profile_pic} sx={{ width: 56, height: 56, mr: 2 }}>
            {`${visit.invitation.resident.first_name[0]}${visit.invitation.resident.last_name[0]}`}
          </Avatar>
          <Typography variant="h5">
            {`${visit.invitation.resident.first_name} ${visit.invitation.resident.last_name}`}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <CalendarToday sx={{ mr: 2 }} />
          <Box>
            <Typography variant="body2" color="text.secondary">Fecha</Typography>
            <Typography>
              {format(invitationDate, "EEEE d 'de' MMMM, yyyy", { locale: es })}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AccessTime sx={{ mr: 2 }} />
          <Box>
            <Typography variant="body2" color="text.secondary">Hora</Typography>
            <Typography>{format(invitationDate, "HH:mm")}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 2 }}>
          <Apartment sx={{ mr: 2 }} />
          <Box>
            <Typography variant="body2" color="text.secondary">Lugar</Typography>
            <Typography>{visit.invitation.apartment.building.name}</Typography>
            <Typography>Depa {visit.invitation.apartment.apartment_number}</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <LocationOn sx={{ mr: 2 }} />
          <Box>
            <Typography variant="body2" color="text.secondary">Dirección</Typography>
            <Typography>{visit.invitation.apartment.building.address}</Typography>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default VisitDetail;