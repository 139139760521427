import React, { createContext, useState, useContext } from 'react';

const InvitationContext = createContext();

export const useInvitation = () => useContext(InvitationContext);

export const InvitationProvider = ({ children }) => {
  const [invitationToken, setInvitationToken] = useState(null);

  return (
    <InvitationContext.Provider value={{ invitationToken, setInvitationToken }}>
      {children}
    </InvitationContext.Provider>
  );
};