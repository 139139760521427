import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';

const EmailInput = ({ onSubmit }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(email);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Correo electrónico
      </Typography>
      <TextField
        required
        label="Correo electrónico"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
        placeholder="example@gmail.com"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
        Siguiente
      </Button>
    </Box>
  );
};

export default EmailInput;