import { supabase } from '../../shared/utils/supabaseClient';
import { api } from '../../shared/services/api';

export const authService = {
  async register(email, password, firstName, lastName, dni) {
    const { data: existingUsers, error: checkError } = await api.get('users', {
      select: 'email,document_number',
      or: `email.eq.${email},document_number.eq.${dni}`
    });

    if (checkError) throw checkError;

    if (existingUsers && existingUsers.length > 0) {
      throw new Error("Un usuario con este correo electrónico o DNI ya está registrado");
    }

    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
          document_type: 'dni',
          document_number: dni,
        }
      }
    });

    if (error) throw error;

    await api.post('users', {
      id: data.user.id,
      email: email,
      first_name: firstName,
      last_name: lastName,
      document_type: 'dni',
      document_number: dni,
    });

    return data.user;
  },

  async login(email, password) {
    // console.log('authService.login called with:', email);
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      // console.error('Supabase login error:', error);
      throw error;
    }
    // console.log('Supabase login successful:', data.user);
    return data.user;
  },

  async getUserProfile(userId) {
    try {
      const data = await api.get('users', {
        filter: { id: userId },
        single: true
      });
      return data;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      throw error;
    }
  },

  async logout() {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
  },
  
  async resetPassword(email) {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/reset-password`,
    });
    if (error) throw error;
  },
  
  async signUpWithOtp(email) {
    const temporaryPassword = Math.random().toString(36).slice(-8);
    try {
      console.log('Attempting to sign up user with email:', email);
      const { data, error } = await supabase.auth.signUp({
        email,
        password: temporaryPassword,
        options: {
          data: {
            document_number: 'PENDING',
            is_active: true,
          }
        }
      });
      if (error) throw error;
      console.log('Signup successful. User data:', data);
      return data;
    } catch (error) {
      console.error('Unexpected error during signUp:', error);
      throw error;
    }
  },

  async cancelSignUp(email) {
    try {
      console.log('Attempting to cancel signup for:', email);
      
      // Find the user by email
      const { data: users, error: findError } = await supabase
        .from('users')
        .select('id')
        .eq('email', email)
        .limit(1);

      if (findError) throw findError;

      if (users && users.length > 0) {
        const userId = users[0].id;
        
        // Delete the user from the auth.users table
        const { error: deleteError } = await supabase.auth.admin.deleteUser(userId);
        if (deleteError) throw deleteError;

        console.log('Successfully cancelled signup for:', email);
      } else {
        console.log('No user found with email:', email);
      }
    } catch (error) {
      console.error('Error cancelling signup:', error);
      throw error;
    }
  },

  async verifyOtp(email, token) {
    const { error } = await supabase.auth.verifyOtp({ 
      email, 
      token, 
      type: 'signup' 
    });
    if (error) throw error;
  },

  async updateUserProfile(userId, userData) {
    try {
      // Check if user exists in public.users
      let { data: existingUser, error: fetchError } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();
  
      if (fetchError && fetchError.code !== 'PGRST116') {
        // PGRST116 is the error code for no rows returned
        throw fetchError;
      }
  
      if (!existingUser) {
        // User doesn't exist in public.users, so let's create it
        const { data: newUser, error: insertError } = await supabase
          .from('users')
          .insert([
            {
              id: userId,
              email: userData.email, // Make sure to pass email in userData
              first_name: userData.firstName,
              last_name: userData.lastName,
              document_type: 'dni',
              document_number: userData.dni,
              phone: userData.phone,
              is_active: true,
              roles: ['visitor']
            }
          ])
          .select();
          if (insertError) throw insertError;
        existingUser = newUser[0];
      } else {
        // User exists, so update it
        const { data: updatedUser, error: updateError } = await supabase
          .from('users')
          .update({
            first_name: userData.firstName,
            last_name: userData.lastName,
            document_type: 'dni',
            document_number: userData.dni,
            phone: userData.phone,
          })
          .eq('id', userId)
          .select();
  
        if (updateError) throw updateError;
        existingUser = updatedUser[0];
      }
  
      // Check if all required fields are filled
      const requiredFields = ['first_name', 'last_name', 'document_type', 'document_number'];
      const missingFields = requiredFields.filter(field => !existingUser[field]);
  
      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
      }
  
      return existingUser;
    } catch (error) {
      console.error('Error updating user profile:', error);
      throw error;
    }
  },

  async updateUserPassword(password) {
    const { data, error } = await supabase.auth.updateUser({ password });
    if (error) throw error;
    return data.user;
  }
};