import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import PhoneInputComponent from './PhoneInput';

const UserInfoInput = ({ onSubmit, initialValues = {} }) => {
  const [firstName, setFirstName] = useState(initialValues.firstName || '');
  const [lastName, setLastName] = useState(initialValues.lastName || '');
  const [dni, setDni] = useState(initialValues.dni || '');
  const [phone, setPhone] = useState(initialValues.phone || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({ firstName, lastName, dni, phone });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Typography variant="h6" gutterBottom>
        Datos Básicos
      </Typography>
      <TextField
        required
        label="Nombre"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="Apellidos"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        required
        label="DNI"
        value={dni}
        onChange={(e) => setDni(e.target.value)}
        fullWidth
        margin="normal"
      />
      <PhoneInputComponent
        value={phone}
        onChange={setPhone}
        error={false}
        helperText=""
      />
      <Button 
        type="submit" 
        fullWidth 
        variant="contained" 
        sx={{ mt: 2, borderRadius: '55px', height: '56px' }}
      >
        Siguiente
      </Button>
    </Box>
  );
};

export default UserInfoInput;