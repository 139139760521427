import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useInvitation } from '../../../contexts/InvitationContext';
import { invitationService } from '../../invitation/invitationService';
import { authService } from '../authService';
import EmailInput from '../components/EmailInput';
import EmailConfirmation from '../components/EmailConfirmation';
import UserInfoInput from '../components/UserInfoInput';
import PasswordInput from '../components/PasswordInput';

const Register = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const [userInfo, setUserInfo] = useState({ firstName: '', lastName: '', dni: '', phone: '' });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { invitationToken, setInvitationToken } = useInvitation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    if (token) {
      setInvitationToken(token);
    }
  }, [location, setInvitationToken]);

  const handleEmailSubmit = async (submittedEmail) => {
    try {
      const result = await authService.signUpWithOtp(submittedEmail);
      if (result.user) {
        setEmail(submittedEmail);
        setStep(2);
        setSnackbarMessage('Se ha enviado un código de confirmación a tu correo');
      } else {
        setSnackbarMessage('Error en el proceso de registro');
      }
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Signup error:', error);
      setSnackbarMessage(error.message || "Error al enviar el código de confirmación");
      setOpenSnackbar(true);
    }
  };

  const handleEmailConfirmation = async (token) => {
    try {
      await authService.verifyOtp(email, token);
      setStep(3);
    } catch (error) {
      console.error('OTP verification error:', error);
      setSnackbarMessage(error.message || "Error al verificar el código");
      setOpenSnackbar(true);
    }
  };

  const handleUserInfoSubmit = (info) => {
    setUserInfo(info);
    setStep(4);
  };

  const handlePasswordSubmit = async (password) => {
    try {
      const user = await authService.updateUserPassword(password);
      await authService.updateUserProfile(user.id, {
        email: user.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        dni: userInfo.dni,
        phone: userInfo.phone,
      });

      if (invitationToken) {
        try {
          await invitationService.createVisitFromInvitation(invitationToken, user.id);
          setSnackbarMessage('Visita creada exitosamente');
        } catch (visitError) {
          setSnackbarMessage('Error al crear la visita: ' + visitError.message);
        }
      } else {
        setSnackbarMessage('Registro completado exitosamente');
      }
      setOpenSnackbar(true);
      navigate('/dashboard');
    } catch (error) {
      console.error('Registration completion error:', error);
      setSnackbarMessage(error.message || "Error al completar el registro");
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={{ maxWidth: 400, margin: 'auto', p: 2 }}>
      <Typography variant="h5" component="h1" gutterBottom>
        Crear cuenta
      </Typography>
      {step === 1 && <EmailInput onSubmit={handleEmailSubmit} />}
      {step === 2 && (
        <EmailConfirmation 
          email={email} 
          onConfirmed={handleEmailConfirmation}
          onBack={() => setStep(1)}
        />
      )}
      {step === 3 && (
        <UserInfoInput 
          onSubmit={handleUserInfoSubmit}
          initialValues={userInfo}
        />
      )}
      {step === 4 && (
        <PasswordInput 
          onSubmit={handlePasswordSubmit} 
          onBack={() => setStep(3)}
        />
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Box>
  );
};

export default Register;